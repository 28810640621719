<template>
  <!--begin::Payment Listing-->
  <div class="payment-template">
    <PageHeaderCount
      moduleType="payment"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_payments"
      countkey="payment_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'payment-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('payment:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkAction(item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_payments
                          }}</template>
                          <template v-else>{{
                            item.payment_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="false && getPermission('payment:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    :to="getDefaultRoute('payment.create')"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'payment',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <template v-if="false">
                  <v-menu content-class="custom-menu-list" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="getRows()"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          :class="{ 'line-active': item.sort }"
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" small left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                            <template v-if="item.sort">
                              <v-icon
                                v-if="item.sort == 'asc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon
                                v-if="item.sort == 'desc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-descending</v-icon
                              >
                            </template>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <PageTips module="payment"></PageTips> -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Payment"
          :basicSearchFields="[
            'barcode',
            'unit_no',
            'street_1',
            'street_2',
            'zip_code',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount.sync="defaultColShow.length"
            :dataLoading.sync="dataLoading"
            :rowData.sync="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!dataLoading">
                  <template v-if="!lodash.isEmpty(rowData)">
                    <tr v-for="(data, index) in rowData" :key="index" link>
                      <template v-for="cols in defaultColDefs">
                        <td
                          v-if="cols.visible"
                          :key="cols.id"
                          :class="{
                            'simple-table-td': !cols.checkbox,
                            'checkbox-width-limit': cols.checkbox,
                          }"
                          :style="{ 'max-width': cols.width }"
                          v-on:click="
                            !cols.checkbox
                              ? rowClicked(data)
                              : 'javascript:void(0)'
                          "
                        >
                          <template v-if="cols.checkbox">
                            <v-checkbox
                              dense
                              v-model="selectedRows"
                              v-bind:value="data.id"
                              color="cyan"
                              class="hide-margin"
                              hide-details
                            ></v-checkbox>
                          </template>
                          <template v-else-if="cols.field == 'barcode'">
                            <Barcode :barcode="data.barcode"></Barcode>
                          </template>
                          <template v-else-if="cols.field == 'date'">
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.payment_date"
                            >
                              <b>Payment: </b>
                              {{ formatDate(data.payment_date) }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.due_date"
                            >
                              <b>Invoice Due: </b>
                              {{ formatDate(data.invoice.due_date) }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.sent_date"
                            >
                              <b>Invoice Sent: </b>
                              {{ formatDate(data.invoice.sent_date) }}
                            </p>
                          </template>
                          <template v-else-if="cols.field == 'company_info'">
                            <div class="payment-listing-customer">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Name: </b> {{ data.invoice.attention }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Company: </b>
                                {{ data.customer.display_name }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Email: </b>
                                {{ data.contact_person.primary_email }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Phone: </b>
                                {{ data.contact_person.primary_phone }}
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'amount'">
                            <div class="payment-listing-amount">
                              <p
                                v-if="false"
                                class="m-0 custom-nowrap-ellipsis"
                              >
                                <b>Pending: </b>
                                {{
                                  $accountingJS.formatMoney(
                                    data.invoice.pending_amount
                                  )
                                }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Received: </b>
                                {{ $accountingJS.formatMoney(data.amount) }}
                                <em
                                  class="text--secondary d-block"
                                  v-if="data.status == 4"
                                  >( Partially Paid )</em
                                >
                                <em
                                  class="text--secondary d-block"
                                  v-else-if="data.status == 3"
                                  >( Paid )</em
                                >
                                <em class="text--secondary d-block" v-else
                                  >( Unpaid )</em
                                >
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'invoice'">
                            <div
                              class="payment-listing-invoice"
                              v-on:click.prevent
                            >
                              <p class="m-0 custom-nowrap-ellipsis">
                                <Barcode
                                  route="invoice.detail"
                                  :id="data.invoice.id"
                                  :barcode="data.invoice.barcode"
                                ></Barcode>
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'mode'">
                            <div class="payment-listing-mode">
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ data.payment_mode.text }}
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'added_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.added_by)"
                              :data.sync="data"
                            >
                              <template v-slot:display_name>
                                {{ data.added_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.created_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.added_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else-if="cols.field == 'updated_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.updated_by)"
                              :data.sync="data"
                            >
                              <template v-slot:display_name>
                                {{ data.updated_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.modified_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.updated_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else>
                            <div v-html="printRowData(cols, data)"></div>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr v-else-if="!dataLoading">
                    <td :colspan="defaultColDefs.length" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no payment at the moment.
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Payment</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="position: static"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Payment')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Payment Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import PaymentMixin from "@/core/lib/payment/payment.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { saveAs } from "file-saver";
import { DOWNLOAD } from "@/core/services/store/request.module";
//import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";

export default {
  mixins: [CommonMixin, ListingMixin, PaymentMixin],
  name: "payment-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "payment-listing",
      routeAPI: "payment",
      routeName: "payment",
      routeDetailName: "payment.detail",
      status: "all",
      statusList: [],
      moreActions: [
        {
          title: "Export Payment(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Paid",
          action: "mark_as_paid",
          icon: "mdi-currency-usd",
        },
        {
          title: "Mark as Unpaid",
          action: "mark_as_unpaid",
          icon: "mdi-currency-usd-off",
        },
        {
          title: "Mark as Draft",
          action: "mark_as_draft",
          icon: "mdi-archive",
        },
        { title: "Delete", action: "delete", icon: "mdi-delete" },
        { title: "Download PDF", action: "download_pdf", icon: "mdi-file-pdf" },
        { title: "Print", action: "print", icon: "mdi-printer" },
      ],
    };
  },
  components: {
    PageHeaderCount,
    draggable,
    //PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.bulkPaymentExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("payment");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    bulkPaymentExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "payment/export",
        })
        .then(({ data }) => {
          saveAs(data, "leave-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Payment",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Payment #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Date",
        field: "date",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
        width: "200px",
      },
      {
        headerName: "Reference",
        field: "reference",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "300px",
      },
      {
        headerName: "Customer Info",
        field: "company_info",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Invoice",
        field: "invoice",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "200px",
      },
      {
        headerName: "Payment Mode",
        field: "mode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
        width: "200px",
      },
      {
        headerName: "Amount",
        field: "amount",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 7,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 8,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: true,
        order: 9,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
